.app-container {
    width: 100%;
    height: 100vh;
    max-width: 2580px;
    margin-inline: auto;
}

.app-row-order {
    display: flex;
    width: 100%;
    height: 85%;
}

.build-date {
    position: absolute;
    bottom: 1rem;
    right: 0rem;
    background-color: #dddcdc;
    color: #adadad;
    padding: 10px 20px;
    border-radius: 10px 0 0 10px;
    z-index: 99;
    font-size: 0.7rem;
}

.build-date:hover {
    display: none;
}